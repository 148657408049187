<script>
  import router from "page";
  import Home from "./Home.svelte";
  import Bio from "./Bio.svelte";
  import Vox from "./Vox.svelte";

  let page;

  router("/", () => (page = Home));
  router("/bio", () => (page = Bio));
  router("/vox", () => (page = Vox));
  router("*", () => router.redirect("/"));

  router.start();
</script>

<svelte:component this={page} />
